import {auth} from 'logic/lib.js'

export default function ({text}) {
	const __html = text
		.replace(
			/(https:\/\/directus\.alc\.fhg\.meso\.design\/[^"]+)\?([^"]+)/g,
			`$1?$2&${auth}`
		)
		.replace(/(<? *script)/gi, 'illegalscript')

	return <span className='richtext' dangerouslySetInnerHTML={{__html}} />
}
