import {base_url, auth, fetch_route} from 'logic/lib.js'
import {useRecoilState} from 'recoil'
import {setRecoil} from 'recoil-nexus'
import {
	db_languages,
	db_translations,
	db_articles,
	db_config,
	language_selected,
	loaded,
} from 'logic/states'

import Article from 'components/Article'
import Toolbar from 'components/Toolbar'

const fetch_data_from_server = async () => {
	const [languages, translations, articles, config] = await fetch_route(
		`${base_url}/items/languages?${auth}`,
		`${base_url}/items/translations?${auth}`,
		`${base_url}/items/articles?${auth}
			&fields[]=*
			&fields[]=related_articles.*
		`,
		`${base_url}/items/config?${auth}`
	)

	console.log('on load', {
		languages,
		translations,
		articles,
		config,
	})

	const articles_filtered = articles.filter(a => a.status == 'published')
	const articles_sorted = articles_filtered.sort((a, b) =>
		a.id_for_sorting?.localeCompare(b.id_for_sorting, 'en', {numeric: true})
	)
	// http://localhost:3000/?lang=en#article_2

	// set states to atoms
	setRecoil(db_languages, languages)
	setRecoil(db_translations, translations)
	setRecoil(db_articles, articles_sorted)
	setRecoil(db_config, config)

	// set language
	const lang = new URLSearchParams(window.location.search).get('lang')
	if (lang) setRecoil(language_selected, lang)

	// jump to the article after loading
	const {hash} = window.location
	if (!hash) return setRecoil(loaded, true)
	setTimeout(() => {
		setRecoil(loaded, true)
		document
			.querySelector(window.location.hash)
			?.scrollIntoView({behavior: 'smooth'})
	}, 2000)
}

fetch_data_from_server()

export default function () {
	const [c_articles] = useRecoilState(db_articles)
	const [has_loaded] = useRecoilState(loaded)

	return (
		<>
			{!has_loaded && (
				<h1 className='fixed w-full h-full bg-black flex items-center justify-center'>
					Loading...
				</h1>
			)}
			<div className='divide-y divide-white/30'>
				{c_articles.map(article => (
					<Article key={article.id} model={article} />
				))}
			</div>
			<Toolbar />
		</>
	)
}
