export const base_url = `https://directus.alc.fhg.meso.design`
export const auth = `access_token=ZQIbZ9YpEmJumkQ0LF5UWQE1vDFfq71J`

export const select_articles = (filter, articles) => {
	const {by_id, by_type} = filter
	const ids = [...by_id.map(a => a.articles_id)]
	articles.forEach(({type, id}) => {
		if (by_type.includes(type)) ids.push(id)
	})
	return ids
		.map(id => articles.findIndex(a => a.id == id))
		.filter(a => a !== undefined)
}

export const fetch_route = async (...routes) => {
	const promises = routes.map(route => {
		const fn = async () => {
			try {
				const res = await fetch(route)
				const json = await res.json()
				return json.data
			} catch (e) {
				console.log('route is offline', route)
			}
		}
		return fn()
	})
	return await Promise.all(promises)
}

export const get_config = (atom, key, type = 'string') => {
	const c = atom.find(c => c.id == key)
	return {
		number: parseInt(c?.value) ?? 0,
		string: c?.value ?? '',
	}[type]
}
