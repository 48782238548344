import {getRecoil} from 'recoil-nexus'
import {db_config, db_translations, language_selected} from 'logic/states'

export const get_config = (key, type = 'string') => {
	const get_config = getRecoil(db_config)
	const config = get_config.find(c => c.id == key)
	return {
		number: parseInt(config.value) ?? 0,
		string: config.value ?? '',
	}[type]
}

export const get_translation = key => {
	const c_lang = getRecoil(language_selected)
	const get_translation = getRecoil(db_translations)
	return get_translation.find(t => t.id == key)?.[c_lang] ?? key
}
