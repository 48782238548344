import Icon from 'components/Icon.js'
import {useRecoilState} from 'recoil'
import {language_selected, db_languages} from 'logic/states.js'

export default function () {
	const [db_langs] = useRecoilState(db_languages)
	const [c_lang, setLang] = useRecoilState(language_selected)

	return (
		<div className='fixed bottom-4 left-1/2 md:left-4 -translate-x-1/2 md:transform-none grid grid-flow-col gap-5 justify-start backdrop-blur-md bg-neutral-700/50 px-5 py-4 rounded-2xl border-white/20 border shadow-[0_10px_50px_black]'>
			{/* <Icon
				flip_x
				src='Reload'
				onClick={e => {
					window.location.reload()
					if (siteKiosk) siteKiosk.logout()
				}}
			/> */}
			<div className='grid gap-2 grid-flow-col'>
				{db_langs.map((lang, i) => (
					<a
						key={i}
						className={c_lang == lang.suffix ? 'font-bold' : ''}
						onClick={e => setLang(lang.suffix)}
						style={{textShadow: '0 0 20px black'}}
					>
						{lang.ui}
					</a>
				))}
			</div>
		</div>
	)
}
