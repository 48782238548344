import {language_selected} from 'logic/states'
import {useRecoilState} from 'recoil'
import {get_translation, get_config} from 'logic/config'

import Richtext from 'components/Richtext'

export default function ({model}) {
	const [c_lang] = useRecoilState(language_selected)

	const bubble_color = get_config(`article_type_color_${model.type}`)

	return (
		<section className='article' id={`article_${model.id}`}>
			<div
				className='bubble p-10 rounded-full bg-[rgba(0,0,0,.7)] flex items-center justify-center text-center uppercase'
				style={{
					zIndex: -1,
					border: `4px ${bubble_color} solid`,
					boxShadow: `0 0 60px -10px ${bubble_color}`,
					color: bubble_color,
				}}
			>
				{get_translation(`article_type_${model.type}`)}
			</div>
			<div className='max-w-[700px] self-center'>
				<Richtext text={model[`bodytext_${c_lang}`]} />
			</div>
		</section>
	)
}
