import {atom} from 'recoil'

export const loaded = atom({
	key: 'loaded',
	default: false,
})

// LANGUAGE
export const language_selected = atom({
	key: 'language_selected',
	default: 'de',
})

export const db_languages = atom({
	key: 'db_languages',
	default: [],
})

export const db_translations = atom({
	key: 'db_translations',
	default: [],
})

export const db_config = atom({
	key: 'db_config',
	default: [],
})

// ARTICLES
export const db_articles = atom({
	key: 'db_articles',
	default: [],
})
